import { create } from 'zustand'

import type { GuestInfo, UserInfo } from 'lib/auth'

interface SessionState {
  session: UserInfo | GuestInfo
  v2Token: string
}

interface SessionAction {
  setSession: (session: SessionState['session']) => void
}

export const useSessionStore = create<SessionState & SessionAction>(set => ({
  session: {
    access_token: '',
    user_id: '',
    guest: true
  },
  v2Token: '',
  setSession: session => set({ session })
}))
