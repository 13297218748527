'use client'

import * as SeparatorPrimitive from '@radix-ui/react-separator'

import { twc, type TwcComponentWithAsChild } from 'util/cn'

type SeparatorProps = TwcComponentWithAsChild<typeof SeparatorPrimitive.Root>
const Separator = twc(SeparatorPrimitive.Root).attrs<SeparatorProps>(props => ({
  asChild: props.$asChild
}))(props => [
  `shrink-0 bg-glass_special-separators`,
  props.orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]'
])
Separator.displayName = `twc(${SeparatorPrimitive.Root.displayName}))`

export { Separator }
