export const GTM_KEY = process.env.NEXT_PUBLIC_GTM_ID as string

export const GA4_KEY = process.env.NEXT_PUBLIC_GA4_ID as string

export const GA_CATEGORY = {
  hashtag: 'Hashtag',
  place: 'Place',
  post: 'Spots',
  spot: 'SpotFeed',
  profile: 'Profile',
  community: 'Community',
  home: 'Home',
  pixie: 'Pixie',
  search: 'Search',
  media: 'media',
  errorHandling: 'Error Handling'
} as const

export const GA_COMMON_EVENT = {
  Main: 'Main',
  Title_PlayseeLogo_Click: 'Title_PlayseeLogo_Click',
  Title_Download_Click: 'Title_Download_Click',
  Cookie_Show: 'Cookie_Show',
  CookieOK_Click: 'CookieOK_Click'
} as const

export const GA_PAGEVIEW = {
  hashtag: '/Web_Hashtag',
  askNearby: '/Web_AsknearBy',
  profile: '/Web_Profile'
}

export const GA_SHOW_ACTION = {
  Comment: {
    mediaShowAction: 'CM_Media_Show',
    urlShowAction: 'CM_Url_Show',
    imgShowAction: 'CM_Img_Show',
    spotShowAction: 'CM_Spot_Show'
  },
  Common: {
    urlShowAction: 'Url_Show',
    imgShowAction: 'Img_Show',
    imgBookmarkShowAction: 'Img_Bookmark_Show',
    spotShowAction: 'Spot_Show'
  },
  Spots: {},
  Profile: {
    bioShowAction: 'Bio_Show',
    noBoardYetShowAction: 'NoBoardYet_Show',
    noSpotYetShowAction: 'NoSpotYet_Show',
    noPostYetShowAction: 'NoPostYet_Show'
  },
  Hashtag: {},
  Boards: {
    noCommentYetShowAction: 'NoCommentYet_Show'
  },
  FollowShare: {},
  DownloadPopup: {
    showAction: 'DLPopup_Show'
  }
}

export const GA_CLICK_ACTION = {
  Comment: {
    headShotClickAction: 'CM_Headshot_Click',
    uidClickAction: 'CM_Uid_Click',
    mediaClickAction: 'CM_Media_Click',
    urlClickAction: 'CM_Url_Click',
    imgClickAction: 'CM_Img_Click',
    spotClickAction: 'CM_Spot_Click',
    spotUnmuteClickAction: 'CM_SpotUnmute_Click',
    spotMuteClickAction: 'CM_SpotMute_Click',
    textClickAction: 'CM_Text_Click',
    replyClickAction: 'CM_Reply_Click',
    likeIconClickAction: 'CM_LikeIcon_Click',
    moreIconClickAction: 'CM_MoreIcon_Click',
    viewMoreClickAction: 'CM_ViewMore_Click'
  },
  Common: {
    closeClickAction: 'Close Click',
    headshotClickAction: 'Headshot_Click',
    uidClickAction: 'Uid_Click',
    captionClickAction: 'Caption_Click',
    urlClickAction: 'Url_Click',
    imgClickAction: 'Img_Click',
    imgArrowRClickAction: 'Img_ArrowR_Click',
    imgArrowLClickAction: 'Img_ArrowL_Click',
    locationClickAction: 'Location_Click',
    bookmarkIconClickAction: 'BookmarkIcon_Click',
    likeIconClickAction: 'LikeIcon_Click',
    shareIconClickAction: 'ShareIcon_Click',
    moreIconClickAction: 'MoreIcon_Click',
    commentIconClickAction: 'CommentIcon_Click',
    profileClickAction: 'Profile Click'
  },
  Spots: {
    screenClickAction: 'Screen_Click',
    unmuteIconClickAction: 'UnmuteIcon_Click',
    muteIconClickAction: 'MuteIcon_Click'
  },
  Profile: {
    followersClickAction: 'Followers_Click',
    followingClickAction: 'Following_Click',
    spotsClickAction: 'Spots_Click',
    bioClickAction: 'Bio_Click',
    profileNameClickAction: 'ProfileName_Click',
    spotClickAction: 'Spot_Click',
    boardListViewAllCommentClickAction: 'BoardList_ViewAllComment_Click',
    boardListShareYourThoughtClickAction: 'BoardList_ShareYourThought_Click',
    tabBoardsClickAction: 'TabBoards_Click',
    tabSpotsClickAction: 'TabSpots_Click'
  },
  Hashtag: {
    hashtagClickAction: 'HashtagName_Click',
    spotsAndViewsClickAction: 'SpotsAndViews_Click',
    spotsAllClickAction: 'SpotsAll_Click', // 點擊任一Spots截圖
    spotsLClickAction: 'SpotsL_Click', // 點擊Spots大圖
    spotsLLikeIconClickAction: 'SpotsL_LikeIcon_Click', // 點擊Spots大圖的愛心
    spotsSClickAction: 'SpotsS_Click' // 點擊Spots小圖
  },

  Boards: {
    commentbarClickAction: 'Commentbar_Click'
  },
  FollowShare: {
    followBtnClickAction: 'FollowBtn_Click'
  },
  DownloadPopup: {
    expBtnClickAction: 'DLPopup_ExpBtn_Click',
    closeClickAction: 'DLPopup_Close_Click'
  },
  Share: {
    shareIconClickAction: 'ShareIcon_Click',
    shareWhatsAppClickAction: 'ShareWhatsApp_Click',
    shareFacebookClickAction: 'ShareFacebook_Click',
    shareMessengerClickAction: 'ShareMessenger_Click',
    shareTwitterClickAction: 'ShareTwitter_Click',
    shareCopyLinkClickAction: 'ShareCopyLink_Click',
    shareEmbedClickAction: 'ShareEmbed_Click'
  },
  Header: {
    miniTitleClickAction: 'MiniTitle_Click'
  },
  GoogleMap: {
    locateAcceptAction: 'Locate Me Accept Location',
    locateDeclineAction: 'Locate Me Decline Location'
  },
  Asknearby: {
    cardSwipeAction: 'Card Swipe',
    questionClickActionGA: 'Question Click'
  }
}

export const GA_CLICK_LABEL = {
  Asknearby: {
    videoReplierProfileClickLabel: 'Ask Nearby Video Reply Profile Click',
    videoReplierUsernameClickLabel: 'Ask Nearby Video Reply Username Click',
    videoReplierCloseClickLabel: 'Ask Nearby Video Reply Close Click',
    questionCardSwiperLabel: 'Ask Nearby Question Card Swipe',
    dotClickLabelGA: 'AskNearby Bubble Question Mark Click',
    ballClickLabelGA: 'AskNearby Bubble Question Text Click'
  }
}
