import { create } from 'zustand'

interface DeviceState {
  device: 'mobile' | 'tablet' | 'desktop'
}
interface DeviceAction {
  setDevice: (device: DeviceState['device']) => void
}
export const useDeviceStore = create<DeviceState & DeviceAction>(set => ({
  device: 'mobile',
  setDevice: device => set({ device })
}))

export const useIsMobile = () =>
  useDeviceStore(state => state.device === 'mobile')
