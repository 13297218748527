// 已定義的頁面
export const DEFINED_PAGES = [
  'search',
  'community',
  'profile',
  'hashtag',
  'pixie',
  'spot',
  'place',
  'reset',
  // terms page
  'ad-policies',
  'ad-terms',
  'biz-terms',
  'coupon-terms',
  'intellectual-property',
  'open-source',
  'privacy',
  'rules',
  'user-terms'
] as const

const getPage = (paths: string[]) => {
  if (paths[1] === '') {
    return 'home'
  }

  return (
    DEFINED_PAGES.find(page => page === paths[1]?.toLowerCase()) ||
    'profile_new'
  )
}

const currentPage = (
  path: string
): {
  page:
    | (typeof DEFINED_PAGES)[number]
    | 'profile_new'
    | 'home'
    | 'spot-feed'
    | 'community-feed'
  id: string
} => {
  const paths = path.split('/')
  const page = getPage(paths)
  const id = decodeURIComponent(
    (page === 'profile_new' ? paths[1] : paths[2]) || ''
  )

  if (page === 'spot' && !id) {
    return { page: 'spot-feed', id }
  }
  if (page === 'community' && !id) {
    return { page: 'community-feed', id }
  }

  return { page, id }
}

export default currentPage
