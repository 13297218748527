'use client'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { GA4_KEY, GA_CATEGORY, GTM_KEY } from 'constant'
import { usePathname } from 'lib/next-intl'
import Script from 'next/script'
import { type ValueOf } from 'type/object'
import { SendGAEvent } from 'util/GA'
import currentPage from 'util/currentPage'
import prefixGAAction from 'util/prefixGAAction'

type GACategory = ValueOf<typeof GA_CATEGORY> | ''

const GARegistry = () => {
  const pathname = usePathname()

  const { page, id } = currentPage(pathname)

  const getGAEventCategory = () => {
    switch (page) {
      case 'home':
        return GA_CATEGORY.home
      case 'search':
        return GA_CATEGORY.search
      case 'spot':
        return id ? GA_CATEGORY.post : GA_CATEGORY.spot
      case 'hashtag':
        return GA_CATEGORY.hashtag
      case 'profile':
        return GA_CATEGORY.profile
      case 'community':
        return GA_CATEGORY.community
      default:
        return GA_CATEGORY.profile
    }
  }

  const gaCategory = getGAEventCategory()

  useEffect(() => {
    GTM_KEY && TagManager.initialize({ gtmId: GTM_KEY })
  }, [])

  // Register click event
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      let target = e.target as HTMLElement
      let gaAction = target.dataset?.gaClick
      let gaLabel = target.dataset?.gaLabel

      while (!gaAction && target.parentElement) {
        target = target.parentElement
        gaAction = target.dataset?.gaClick
        gaLabel = target.dataset?.gaLabel
      }

      if (gaCategory && gaAction) {
        const gaActions = gaAction.split(',')
        gaActions.forEach(action => {
          SendGAEvent(gaCategory, prefixGAAction(action, gaCategory), gaLabel)
        })
      }
    }
    document.addEventListener('click', onClick)
    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [gaCategory])

  // Register show event
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const target = entry.target as HTMLElement
            const gaAction = target.dataset?.gaShow
            const gaLabel = target.dataset?.gaLabel
            if (gaCategory && gaAction) {
              const gaActions = gaAction.split(',')
              gaActions.forEach(action => {
                SendGAEvent(
                  gaCategory,
                  prefixGAAction(action, gaCategory),
                  gaLabel
                )
              })
              intersectionObserver.unobserve(target)
            }
          }
        })
      },
      { threshold: 0.5 }
    )

    Array.from(document.querySelectorAll('[data-ga-show]')).forEach(node => {
      intersectionObserver.observe(node)
    })

    // observe new added node
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if (node instanceof HTMLElement) {
            if (node.dataset?.gaShow) {
              intersectionObserver.observe(node)
            }

            // some children node not listed in mutation.addedNodes
            // so we need to use TreeWalker to find all nodes
            const walker = document.createTreeWalker(
              node,
              NodeFilter.SHOW_ELEMENT,
              node => {
                if (node instanceof HTMLElement && node.dataset?.gaShow) {
                  return NodeFilter.FILTER_ACCEPT
                }
                return NodeFilter.FILTER_SKIP
              }
            )

            while (walker.nextNode()) {
              const node = walker.currentNode as HTMLElement
              intersectionObserver.observe(node)
            }
          }
        })
      })
    })

    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true
    })

    return () => {
      mutationObserver.disconnect()
      intersectionObserver.disconnect()
    }
  }, [gaCategory])

  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4_KEY}`}
      />
      <Script id='google-analytics'>
        {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${GA4_KEY}');
  `}
      </Script>
    </>
  )
}

export default GARegistry
