'use client'
import { useRef } from 'react'

import type { GuestInfo, GuestPayload, UserInfo } from 'lib/auth'
import { type ILocationIpV2 } from 'type/geo'
import { useCookieConsentStore } from './cookie-conset'
import { useDeviceStore } from './device'
import { useIpLocationStore } from './ip-location'
import { useServerContextStore } from './server-context'
import { useSessionStore } from './session'

interface IStoreInitializer {
  ip: string
}
export const StoreInitializer = (props: IStoreInitializer) => {
  const { ip } = props
  const initialRef = useRef(false)
  if (!initialRef.current) {
    useServerContextStore.setState({ ip })
    initialRef.current = true
  }

  return null
}

export const CookieConsentInitializer = (props: {
  consentCookie: string | undefined
}) => {
  const { consentCookie } = props
  const initialRef = useRef(false)
  if (!initialRef.current) {
    useCookieConsentStore.setState({ accepted: consentCookie === 'true' })
    initialRef.current = true
  }

  return null
}

export const DeviceInitializer = (props: {
  device: 'mobile' | 'tablet' | 'desktop'
}) => {
  const { device } = props
  const initialRef = useRef(false)
  if (!initialRef.current) {
    useDeviceStore.setState({ device })
    initialRef.current = true
  }

  return null
}

export const SessionInitializer = (props: {
  session: UserInfo | GuestInfo
  v2Token: string
}) => {
  const { session, v2Token } = props
  const initialRef = useRef(false)
  if (!initialRef.current) {
    useSessionStore.setState({ session, v2Token })
    initialRef.current = true
  }

  return null
}

export const IpLocationInitializer = (props: {
  location: ILocationIpV2 | null
}) => {
  const { location } = props
  const initialRef = useRef(false)
  if (!initialRef.current) {
    useIpLocationStore.setState({ ipLocation: location })
    initialRef.current = true
  }

  return null
}
