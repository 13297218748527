'use client'

import { cva, type VariantProps } from 'class-variance-authority'

import { twc, type TwcComponentProps } from 'util/cn'

const buttonVariants = cva(
  'ease relative flex flex-nowrap items-center justify-center border-[1px] border-transparent text-md font-semibold transition-all duration-300 enabled:cursor-pointer',
  {
    variants: {
      $variant: {
        primary:
          'bg-primary-p1 text-primary-on_primary disabled:bg-[rgba(76,142,180,30%)]',
        secondary: 'bg-secondary-p2 text-secondary-p1',
        third: 'bg-third-p1 text-third-on_third',
        outline:
          'border-label-l3 bg-background-1st text-label-l1 disabled:text-label-l3',
        'outline-white':
          'border-label_still-l1 bg-transparent text-label_still-l1',
        text: 'bg-transparent text-primary-p1',
        'text-white': 'bg-transparent text-label_still-l1',
        'text-still': 'bg-transparent text-label-l1 disabled:text-label-l3',
        gray: 'bg-background-2nd text-label-l1'
      },
      $radius: {
        sm: 'rounded-[2px]',
        md: 'rounded-[6px]',
        lg: 'rounded-[8px]',
        xl: 'rounded-[12px]',
        round: 'rounded-full'
      }
    },
    defaultVariants: {
      $variant: 'primary',
      $radius: 'md'
    }
  }
)

const sizeVariants = cva('', {
  variants: {
    $size: {
      xs: 'px-[6px] py-[3px]',
      sm: 'px-[12px] py-[6px]',
      md: 'px-[14px] py-[10px]',
      lg: 'px-[18px] py-[14px]',
      // Temp xl size
      xl: 'px-[24px] py-[18px]'
    }
  },
  defaultVariants: {
    $size: 'md'
  }
})

const iconSizeVariants = cva('', {
  variants: {
    $size: {
      xs: 'h-[16px] w-[16px]',
      sm: 'h-[24px] w-[24px]',
      md: 'h-[32px] w-[32px]',
      lg: 'h-[40px] w-[40px]',
      xl: 'h-[48px] w-[48px]'
    }
  },
  defaultVariants: {
    $size: 'md'
  }
})

type ButtonVariants = VariantProps<typeof buttonVariants>
type SizeVariants = VariantProps<typeof sizeVariants>
type ButtonProps = TwcComponentProps<'button'> &
  ButtonVariants &
  SizeVariants & {
    asChild?: boolean
    $icon?: boolean
  }

const Button = twc.button<ButtonProps>(
  ({ $icon, $radius, $size, $variant }) => [
    buttonVariants({ $radius, $variant }),
    $icon ? iconSizeVariants({ $size }) : sizeVariants({ $size })
  ]
)
Button.displayName = 'Button'

const ButtonGroup = twc.div`flex flex-col space-y-[12px] pt-[8px]`

export { Button, ButtonGroup }
