import { type GA_CATEGORY } from 'constant'
import { useDeviceStore } from 'store/server-context/device'
import { type ValueOf } from 'type/object'

type GA_CATEGORY_TYPE = ValueOf<typeof GA_CATEGORY> | ''

const prefixGAAction = (action: string, category: GA_CATEGORY_TYPE): string => {
  const isMobile = useDeviceStore.getState().device === 'mobile'
  return `${isMobile ? 'Mob' : 'Des'}_${category}_${action}`
}

export default prefixGAAction
