import { IsServer } from 'util/browser'

export const SendGAEvent = (
  category: string,
  action: string,
  label?: string
) => {
  if (IsServer() || !category || !action) return

  if ('gtag' in window && window.gtag) {
    ;(window.gtag as any)(
      'event',
      `${category}_${action}${label ? `_${label}` : ''}`
    )
  }
}
