import { create } from 'zustand'

interface ServerContextState {
  ip: string
  acceptLanguage: string
}

interface ServerContextAction {
  setServerContext: (state: ServerContextState) => void
}

export const useServerContextStore = create<
  ServerContextState & ServerContextAction
>(set => ({
  ip: '',
  acceptLanguage: '',
  setServerContext: state => set(state)
}))
