import Cookies from 'js-cookie'
import { useEffect, useRef } from 'react'

import { CONSENT_COOKIE_NAME } from 'constant'
import { useCookieConsentStore } from 'store/server-context/cookie-conset'

function useCookieConsent(options?: Cookies.CookieAttributes) {
  const accepted = useCookieConsentStore(state => state.accepted)
  const safeOptionsRef = useRef<Cookies.CookieAttributes>(options || {})

  useEffect(() => {
    Cookies.set(CONSENT_COOKIE_NAME, accepted ? 'true' : 'false', {
      expires: 365,
      sameSite: 'lax',
      secure: window.location ? window.location.protocol === 'https:' : true,
      ...safeOptionsRef.current
    })
  }, [accepted])

  const acceptCookie = () => {
    useCookieConsentStore.setState({ accepted: true })
  }

  return {
    accepted,
    acceptCookie
  }
}

export default useCookieConsent
