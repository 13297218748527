'use client'
import * as React from 'react'

import cn from 'util/cn'
import { Renderable, resolveValue, Toast, ToastPosition } from './types'

const prefersReducedMotion = (() => {
  // Cache result
  let shouldReduceMotion: boolean | undefined = undefined

  return () => {
    if (shouldReduceMotion === undefined && typeof window !== 'undefined') {
      const mediaQuery = matchMedia('(prefers-reduced-motion: reduce)')
      shouldReduceMotion = !mediaQuery || mediaQuery.matches
    }
    return shouldReduceMotion
  }
})()

interface ToastBarProps {
  toast: Toast
  position?: ToastPosition
  style?: React.CSSProperties
  children?: (components: { message: Renderable }) => Renderable
}

const getAnimationClass = (position: ToastPosition) => {
  const top = position.includes('top')
  const factor = top ? 1 : -1

  const [enter, exit] = prefersReducedMotion()
    ? ['animate-in fade-in', 'animate-out fade-out']
    : ['animate-toast-enter', 'animate-toast-exit']

  return {
    enter,
    exit,
    factor
  }
}

export const ToastBar: React.FC<ToastBarProps> = React.memo(
  ({ toast, position, style, children }) => {
    const { enter, exit, factor } = toast.height
      ? getAnimationClass(toast.position || position || 'top-center')
      : {
          enter: 'opacity-0',
          exit: 'opacity-0',
          factor: 0
        }

    const animationClassName = toast.visible ? enter : exit

    const message = (
      <div
        className='mx-[10px] my-[4px] flex flex-shrink flex-grow basis-auto justify-center whitespace-pre-line [color:inherit]'
        {...toast.ariaProps}
      >
        {resolveValue(toast.message, toast)}
      </div>
    )

    return (
      <div
        className={cn(
          'pointer-events-auto flex max-w-[350px] items-center rounded-[20px] bg-glass_ios-20blur_still px-[10px] py-[8px] text-md text-label_still-l1 shadow-toast',
          animationClassName,
          toast.className
        )}
        style={
          {
            ...style,
            '--factor': factor,
            ...toast.style
          } as React.CSSProperties
        }
      >
        {typeof children === 'function' ? (
          children({
            message
          })
        ) : (
          <>{message}</>
        )}
      </div>
    )
  }
)
