import { type ILocationIpV2 } from 'type/geo'
import { create } from 'zustand'

interface IpLocationState {
  ipLocation: ILocationIpV2 | null
}

interface IpLocationAction {
  setUserLocation: (userLocation: IpLocationState['ipLocation']) => void
}

export const useIpLocationStore = create<IpLocationState & IpLocationAction>(
  set => ({
    ipLocation: null,
    setUserLocation: userLocation => set({ ipLocation: userLocation })
  })
)
