'use client'
import type { ReactNode } from 'react'

import Toaster from 'component/block/toaster'
import QueryClientProvider from 'component/queryClient'
import GARegistry from './gaRegistry'

const toastConfig = {
  containerStyle: {
    top: '50%',
    left: 0,
    bottom: 0,
    right: 0
  },
  toastOptions: {
    duration: 3000,
    className: 'animate-in fade-in'
  }
}

export const MainProviders = ({ children }: { children: ReactNode }) => {
  return (
    <QueryClientProvider>
      <GARegistry />
      <Toaster {...toastConfig} />
      {children}
    </QueryClientProvider>
  )
}
