export type Env = 'local' | 'develop' | 'stage' | 'beta' | 'master'
export const env = process.env.NEXT_PUBLIC_ENVIRONMENT as Env

export const PACKAGE_NAME = process.env.NEXT_PUBLIC_PACKAGE_NAME
export const PACKAGE_VERSION = process.env.NEXT_PUBLIC_PACKAGE_VERSION

// --- Url related ---
const APP_ID = '1466926345'
const APP_NAME = 'com.framy.placey'
export const APP_DOWNLOAD_LINKS = {
  ios: `https://apps.apple.com/app/id${APP_ID}`,
  android: `https://play.google.com/store/apps/details?id=${APP_NAME}`
}

type LinkPrefix = 'about' | 'business' | 'help' | 'careers' | 'love'
type LinkEnv = 'co' | 'beta' | 'dev'
type BuildLink<Prefix extends LinkPrefix> = Record<
  Prefix,
  `https://${Prefix}.playsee.${LinkEnv}/`
>
type Links = BuildLink<'love'> &
  BuildLink<'about'> &
  BuildLink<'business'> &
  BuildLink<'help'> &
  BuildLink<'careers'> & { platform: `https://playsee.${LinkEnv}/` }

export const PLAYSEE_LINKS = new Proxy(
  {},
  {
    get: (_, key: LinkPrefix | 'platform') => {
      const suffix = env === 'master' ? 'co' : env === 'beta' ? 'beta' : 'dev'
      const domain = key === 'platform' ? 'playsee' : `${key}.playsee`
      return `https://${domain}.${suffix}/`
    }
  }
) as Links

export const LEGAL_LINKS = {
  rules: `${PLAYSEE_LINKS.platform}rules`,
  privacy: `${PLAYSEE_LINKS.platform}privacy`,
  terms: `${PLAYSEE_LINKS.platform}user-terms`,
  adTerms: `${PLAYSEE_LINKS.platform}ad-terms`
} as const

export const BASE_URL =
  env === 'local' ? 'http://localhost:3000/' : PLAYSEE_LINKS.platform
export const REDIRECT_APP_LINK = PLAYSEE_LINKS.love

export const FIREBASE_DYNAMIC_LINK =
  'https://connect.playsee.co/?link={link}&apn=com.framy.placey&isi=1466926345&ibi=com.framy.placey'
export const CDN_URL = 'https://storage.googleapis.com/playsee-web'

export const NO_PHOTO_PATH = CDN_URL + '/platform/images/no_photo.svg' // if path is public can use /image...
export const PLAYSEE_LOGO = CDN_URL + '/common/images/favicon.png'
export const PLAYSEE_BANNER = CDN_URL + '/common/images/playsee_banner.jpg'

export const FAKE_IP = '114.32.52.170'

// Other social media links
export const TWITTER_LINK = 'https://twitter.com/playseeofficial'
export const FACEBOOK_LINK = 'https://www.facebook.com/playseeofficial'
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/playseeapp'
export const INSTAGRAM_LINK = 'https://www.instagram.com/playseeofficial'

// --- Share related ---
export const FACEBOOK = 'facebook'
export const MESSENGER = 'messenger'
export const TWITTER = 'twitter'
export const WHATS_APP = 'whatsApp'
export const COPY_LINK = 'copyLink'

// --- Authorization related ---
export const OLD_TOKEN_PREFIX = 'ad52e3866ee135ff5d92545349414868#0#'
export const COMMON_HOST_ENDPOINT = OLD_TOKEN_PREFIX + 'web20231225'

export const TEMP_USER_ID = '0'

// --- Other config ---
export const CONSENT_COOKIE_NAME = 'pca'
export const USER_COOKIE_NAME = '__playsee_user'
export const USER_INFO_COOKIE_NAME = '__playsee_user_info'
export const AUTH_COOKIE_NAME = '__playsee_session'

export const TIME_OUT = {
  ONE_SECOND: 1000,
  FIVE_SECOND: 1000 * 5
}

export const GOOGLE_MAPS_API_KEY = process.env
  .NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string

export const DEFAULT_VIEWPORT: {
  readonly NELat: number
  readonly NELng: number
  readonly SWLat: number
  readonly SWLng: number
} = {
  NELat: 90,
  NELng: 180,
  SWLat: -90,
  SWLng: -180
}
export const DEFAULT_MAP_CENTER = {
  lat: 25.033,
  lng: 121.5654
}

export const HASHTAG_POST_SIZE = 12
export const SPOT_SIZE = 12

export const POPUP_TRIGGER_HEIGHT = {
  // @deprecated
  board: 2500,
  // @deprecated
  post: 1800,
  comment: 2500,
  spot: 1800
}

export const HEADER_LOCATION_CONTAINER_ID = 'header-location-container'

export const LOCATION_COOKIE_NAME = 'USER_LOCATION'

export const NO_MORE_DATA = 'NO_MORE_DATA'

export const FOLLOW_STATUS = {
  None: 'not_followed',
  Followed: 'followed',
  Requested: 'requested'
}

export const BIRTHDAY_FORMAT = 'MM/DD/YYYY'

export const APP_HOME = '/community'
export const SITE_HOME = '/'

export enum HeadshotType {
  Video = 0,
  Image = 1,
  None
}

export const HeadshotSize = {
  xxs: 18,
  xs: 24,
  sm: 36,
  md: 48,
  lg: 64,
  xl: 96,
  xxl: 128
} as const
