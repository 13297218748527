'use client'

import { useTranslations } from 'next-intl'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import Link from 'component/block/link'
import { Button } from 'component/ui/button'
import { Separator } from 'component/ui/separator'
import { GA_CATEGORY, GA_COMMON_EVENT } from 'constant'
import { usePathname } from 'lib/next-intl'
import { useIsMobile } from 'store/server-context/device'
import type { ValueOf } from 'type/object'
import { SendGAEvent } from 'util/GA'
import cn from 'util/cn'
import currentPage from 'util/currentPage'
import prefixGAAction from 'util/prefixGAAction'
import useCookieConsent from './useCookieConsent'

const pageMap: Record<string, ValueOf<typeof GA_CATEGORY>> = {
  home: GA_CATEGORY.home,
  hashtag: GA_CATEGORY.hashtag,
  place: GA_CATEGORY.place,
  spot: GA_CATEGORY.spot,
  profile: GA_CATEGORY.profile,
  profile_new: GA_CATEGORY.profile,
  post: GA_CATEGORY.post,
  community: GA_CATEGORY.community,
  search: GA_CATEGORY.search
}

const CookieHint = () => {
  const t = useTranslations()
  const [cookieHintHeight, setCookieHintHeight] = useState<number>(0)
  const ref = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const { page } = currentPage(pathname)
  const gaCategory = pageMap[page]

  const { accepted, acceptCookie } = useCookieConsent()

  const onAccept = () => {
    setCookieHintHeight(0)
    acceptCookie()

    SendGAEvent(
      gaCategory,
      prefixGAAction(GA_COMMON_EVENT.CookieOK_Click, gaCategory)
    )
  }

  useEffect(() => {
    if (accepted || !ref.current) return
    const resizeObserver = new ResizeObserver(entries => {
      const { height } = ref.current!.getBoundingClientRect()
      setCookieHintHeight(height)
    })
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect()
  }, [accepted])

  useLayoutEffect(() => {
    if (!isMobile) {
      return
    }

    const root = document.documentElement
    root.style.setProperty('--cookie-hint-height', `${cookieHintHeight}px`)
  }, [cookieHintHeight, isMobile])

  useEffect(() => {
    if (!accepted && gaCategory) {
      SendGAEvent(
        gaCategory,
        prefixGAAction(GA_COMMON_EVENT.Cookie_Show, gaCategory)
      )
    }
  }, [accepted, gaCategory])

  const isHome = page === 'home'

  if (accepted) return null

  return (
    <div id='cookie-container' className='w-full'>
      <div
        ref={ref}
        className={cn('cookies-hint text-sm', isHome && 'is-home')}
      >
        <p className='cookies-text'>
          {t.rich('common.cookies_hint', {
            t0: chunks => (
              <Link
                href='/privacy#cookies'
                target='_blank'
                rel='noopener noreferrer'
                className='px-[2px] font-semibold'
              >
                {chunks}
              </Link>
            ),
            link: t('common.cookie_policy')
          })}
        </p>
        <Separator orientation='vertical' className='cookies-separator' />
        <Button
          $variant='text-white'
          $size='sm'
          className='cookies-ok'
          onClick={onAccept}
        >
          {t('common.ok')}
        </Button>
      </div>
    </div>
  )
}

export default CookieHint
