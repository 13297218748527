'use client'

import {
  QueryClient,
  QueryClientProvider as QueryClientProviderBase
} from '@tanstack/react-query'
import { type ReactNode } from 'react'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  }
})

export default function QueryClientProvider({
  children
}: {
  children: ReactNode
}) {
  return (
    <QueryClientProviderBase client={queryClient}>
      {children}
    </QueryClientProviderBase>
  )
}
